import "./ThreeJSTitle.scss";
import * as THREE from "three";
import React, {useEffect, useRef} from "react";
import ThemeContext from "../../../contexts/Themes";
import LanguageContext, {languages} from "../../../contexts/Languages";
import Copyright from "./Copyright";
import ThemeToggler from "./Togglers/ThemeToggler";
import LanguageToggler from "./Togglers/LanguageToggler";

const createThreeJS = async (canvas, backgroundColor) => {
    const maxPixelRatio = 2;

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(backgroundColor);

    const sizes = {
        width: null,
        height: null
    }

    const setSizes = () => {
        const scrollbarWidth = window.innerWidth - document.body.clientWidth
        sizes.width = window.innerWidth - scrollbarWidth;
        sizes.height = window.innerHeight
    }
    setSizes();

    const camera = new THREE.PerspectiveCamera(100, sizes.width / sizes.height);
    scene.add(camera);
    camera.position.z = 10;

    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, maxPixelRatio));

    window.addEventListener('resize', () => {
        setSizes();

        camera.aspect = sizes.width / sizes.height;
        camera.updateProjectionMatrix();

        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        renderer.render(scene, camera);
    });

    const generateRandomFloat = (min, max) => {
        return Math.random() * (max - min) + min;
    }

    const getRandomColor = () => {
        const colors = [
            '#019529', // green
            '#FF8200', // orange
            '#FF0000', // red
        ]
        //backgroundColor === '#ffffff' ? colors.push('#000000') : colors.push('#ffffff');
        return colors[Math.floor(Math.random() * (colors.length))];
    }

    for (let i = 0; i < 100; i++) {
        const color = getRandomColor();
        const cubeSize = generateRandomFloat(0.1, 1);
        const boxGeometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);
        const material = new THREE.MeshBasicMaterial();
        material.color = new THREE.Color(color);
        const cube = new THREE.Mesh(boxGeometry, material);
        cube.position.x = generateRandomFloat(-5, 5);
        cube.position.y = generateRandomFloat(-5, 5);
        cube.position.z = generateRandomFloat(-5, 9);
        scene.add(cube);
    }

    renderer.render(scene, camera);

    return canvas
}

const ThreeJSTitle = ({theme}) => {

    let canvas = useRef(null);

    useEffect(() => {
        createThreeJS(canvas.current, theme)
    })

    return (
        <canvas ref={canvas}></canvas>

    )
}

const Title = ({title}) => {

    return (
        <LanguageContext.Consumer>{({toggleLanguage}) => (
        <ThemeContext.Consumer>{({toggleTheme, theme}) => (
            <div style={{backgroundColor: theme.backgroundColor, color: theme.color}}>
            <div className="titleBox">
                <h1 className="title">
                    <div dangerouslySetInnerHTML={{__html: title}}/>
                    {/*title*/}
                    <br/>
                    <a href="#0" className="material-icons" style={{margin: "8px"}}>arrow_downward</a>
                </h1>
                <div style={{backgroundColor: "black", left: 0, right: 0}}>
                    <LanguageToggler toggleLanguage={toggleLanguage} languages={languages} theme={theme}/>
                    <ThemeToggler toggleTheme={toggleTheme} theme={theme}/>
                </div>
                <Copyright theme={theme}/>
            </div>
            </div>
        )}
        </ThemeContext.Consumer>
        )}
        </LanguageContext.Consumer>
    )
}

export default ThreeJSTitle
export {Title}