import React, {useContext, useEffect, useState} from 'react';
import Article from "./components/Article";
import * as json from "./json/content.json";
import './App.scss';
import ThreeJSCanvas from './components/ThreeJS/ThreeJSCanvas'
import ThemeContext, {themes} from "./contexts/Themes";
import ThreeJSTitle, {Title} from "./components/ThreeJS/ThreeJSTitle";
import languageContext, {languages} from "./contexts/Languages";
import LanguageContext from "./contexts/Languages";

let curTheme;
let curLang;

const App = () => {

    let themeCtx = useContext(ThemeContext);
    let [theme, setTheme] = useState(themeCtx);

    themeCtx.toggleTheme = () => {
        setTheme({
            theme:
                curTheme === themes.dark
                    ? curTheme = themes.light
                    : curTheme = themes.dark,
            toggleTheme: themeCtx.toggleTheme,
        });
    };

    let languageCtx = useContext(languageContext);
    let [language, setLanguage] = useState(languageCtx);

    languageCtx.toggleLanguage = () => {
        setLanguage({
            language:
                curLang === languages.en.json
                    ? curLang = languages.fr.json
                    : curLang = languages.en.json,
            toggleLanguage: languageCtx.toggleLanguage,
        });
    };

    const displaySections = (articles) => {
        return (
        articles.map((article, i) => {
            return (
                <div className="flex-container">
                    <ThreeJSCanvas number={i}/>
                    <Article article={article} number={i} maxArticles={json.fr.articles.length}/>
                </div>
            )
        })
        )
    }

    useEffect(() => {});

    return (
        <LanguageContext.Provider value={language}>
        <ThemeContext.Provider value={theme}>
                <Title title={language.language.title}/>
            {displaySections(language.language.articles)}
        </ThemeContext.Provider>
        </LanguageContext.Provider>
    )
}

export default App;
