import './ThreeJSCanvas.scss'
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader";
import React, {useEffect, useRef} from "react";

const objLoader = new OBJLoader();
const mtlLoader = new MTLLoader();
const clock = new THREE.Clock();

const createObjects0 = async () => {

    const mesh1 = await get3DObject("models/cow/cow.mtl", "models/cow/cow.obj");
    mesh1.position.set(0, -1.5, 0);
    const mesh2 = await get3DObject("models/cow/cow.mtl", "models/cow/cow.obj");
    mesh2.position.set(0, 0.5, 0);
    const mesh3 = await get3DObject("models/cow/cow.mtl", "models/cow/cow.obj");
    mesh3.position.set(0, -0.5, -1);
    const mesh4 = await get3DObject("models/cow/cow.mtl", "models/cow/cow.obj");
    mesh4.position.set(0, -0.5, 1);

    const group = new THREE.Group();
    group.add(mesh1, mesh2, mesh3, mesh4);

    const update = () => {
        const elapsedTime = clock.getElapsedTime();

        group.rotation.y = elapsedTime / 5;

        window.requestAnimationFrame(update)
    }

    update();

    return group;
}

const createObjects2 = async () => {
    const mesh = await get3DObject("models/room/room.mtl", "models/room/room.obj");
    mesh.position.set(0, -1, 0);

    const update = () => {
        const elapsedTime = clock.getElapsedTime();

        mesh.rotation.y = elapsedTime / 5;

        window.requestAnimationFrame(update)
    }

    update();

    return mesh;
}

const createObjects3 = async () => {
    const m = await get3DObject("models/mova/m/m.mtl", "models/mova/m/m.obj");
    m.position.set(-3, 3, -3);
    const o = await get3DObject("models/mova/o/o.mtl", "models/mova/o/o.obj");
    o.position.set(-1, 3, -3);
    const v = await get3DObject("models/mova/v/v.mtl", "models/mova/v/v.obj");
    v.position.set(1, 3, -3);
    const a = await get3DObject("models/mova/a/a.mtl", "models/mova/a/a.obj");
    a.position.set(3, 3, -3);
    const phone = await get3DObject("models/mova/phone/phone.mtl", "models/mova/phone/phone.obj");
    phone.position.set(2, -1, -3);
    const computer = await get3DObject("models/mova/computer/computer.mtl", "models/mova/computer/computer.obj");
    computer.position.set(-0.5, -0.25, 1);

    const group = new THREE.Group();
    group.add(m, o, v, a, phone, computer);

    const update = () => {

        const elapsedTime = clock.getElapsedTime();

        m.rotation.y = elapsedTime / 10;
        o.rotation.y = elapsedTime / 10;
        v.rotation.y = elapsedTime / 10;
        a.rotation.y = elapsedTime / 10;
        phone.rotation.x = elapsedTime / 5;
        computer.rotation.z = elapsedTime / 8;

        window.requestAnimationFrame(update);
    }

    update();

    return group;
}

const createObjects1 = async () => {

    const mesh = await get3DObject("models/house/house.mtl", "models/house/house.obj");
    mesh.position.set(0, -0.75, 0);
    mesh.scale.set(0.75, 0.75, 0.75);

    const update = () => {

        const elapsedTime = clock.getElapsedTime();

        mesh.rotation.y = elapsedTime / 5;

        window.requestAnimationFrame(update);
    }

    update();

    return mesh;
}

const createObjects4 = async () => {

    const mesh = await get3DObject("models/plane/plane.mtl", "models/plane/plane.obj");
    mesh.position.set(0, -0.75, 0);
    mesh.scale.set(1, 1, 1);

    const update = () => {

        const elapsedTime = clock.getElapsedTime();

        mesh.rotation.y = elapsedTime / 5;

        window.requestAnimationFrame(update);
    }

    update();

    return mesh;
}

const get3DObject = (mtlPath, objPath) => {
    return new Promise((resolve) => {
            mtlLoader.load(
                mtlPath,
                (material) => {
                    material.preload()
                    objLoader.setMaterials(material)
                    objLoader.load(
                        objPath,
                        (mesh) => {
                            resolve(mesh);
                        }
                    )
                }
            )
        }
    )
}

const createThreeJS = async (canvas, groupToAdd, backgroundColor) => {

    const scene = new THREE.Scene()
    scene.background = new THREE.Color(backgroundColor);

    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true
    })

    const sizes = {
        width: null,
        height: null
    }

    const setSizes = () => {
        const scrollbarWidth = window.innerWidth - document.body.clientWidth

        if(window.innerWidth <= 500) {
            sizes.width = window.innerWidth;
            sizes.height = window.innerHeight / 2;
        }
        else {
            sizes.width = (window.innerWidth - scrollbarWidth) / 2;
            sizes.height = window.innerHeight
        }
    }
    setSizes()

    window.addEventListener('resize', () => {
        setSizes()

        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    const ambientLight = new THREE.AmbientLight('#ffffff')
    scene.add(ambientLight)

    scene.add(groupToAdd);

    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height)
    scene.add(camera)
    camera.position.z = 3;

    const controls = new OrbitControls(camera, canvas)
    controls.enableDamping = true
    controls.enablePan = false;

    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    const update = () => {

        controls.update()

        renderer.render(scene, camera)

        window.requestAnimationFrame(update)
    }
    update()

    return (<div>{canvas}</div>)
}

const ThreeJSCanvas = ({number}) => {

    const bgColor = '#653d85';

    let canvas = useRef(null);

    useEffect(() => {
        switch (number){
            case 0:
                createObjects0().then((group) => {
                    createThreeJS(canvas.current, group, bgColor)
                })
                break;
            case 1:
                createObjects1().then((group) => {
                    createThreeJS(canvas.current, group, bgColor)
                })
                break;
            case 2:
                createObjects2().then((group) => {
                    createThreeJS(canvas.current, group, bgColor)
                })
                break;
            case 3:
                createObjects3().then((group) => {
                    createThreeJS(canvas.current, group, bgColor)
                })
                break;
            case 4:
                createObjects4().then((group) => {
                    createThreeJS(canvas.current, group, bgColor)
                })
        }
    })
    return (
            <canvas ref={canvas}></canvas>
    )
}

export default ThreeJSCanvas;

