import "./Article.scss"
import ThemeContext from "../../contexts/Themes";
import {useEffect} from "react";

const Article = ({article, number, maxArticles}) => {
    maxArticles--
    const articleID = number;

    const setWidth = () => {
            const sidebarWidth = window.innerWidth - document.body.clientWidth
            Array.from(document.getElementsByClassName('outerBox')).forEach((outerBox) => {
                outerBox.style.width = `${(window.innerWidth - sidebarWidth) / 2}px`
            })
    }

    window.addEventListener('resize', () => {
     setWidth()
    })

    useEffect(()=> {
        setWidth()
    })

    const chapters = article.chapters.map((chapter) => {
        return (
            <div>
                <h2 className="chapterTitle">{chapter.title}</h2>
                <hr/>
                <div className="content" dangerouslySetInnerHTML={{__html: chapter.content}}/>
            </div>
        )
    })

    let nextArticle = {
        id: number+1,
        iconName: "move_down",
    }
    let previousArticle = {
        id: number-1,
        iconName: "move_up",
    }

    switch (number){
        case 0:
            previousArticle.id = maxArticles
            previousArticle.iconName = "arrow_downward"
            break;
        case maxArticles:
            nextArticle.id = 0
            nextArticle.iconName = "arrow_upward"
            break;
    }
    const next = `#${nextArticle.id}`
    const previous = `#${previousArticle.id}`

    return (
        <ThemeContext.Consumer>{({theme}) => (
            <div id={articleID} className="outerBox" style={{
                backgroundColor: theme.backgroundColor, color: theme.color
            }}>
                <div className="innerBox">
                    <div className="introduction" dangerouslySetInnerHTML={{__html: article.introduction}} />
                        <h1 className="articleTitle">
                        {article.title}
                        <a href={next} className="material-icons" style={{margin: "8px"}}>{nextArticle.iconName}</a>
                        <a href={previous} className="material-icons" style={{marginTop: "8px", marginBottom: "8px"}}>{previousArticle.iconName}</a>
                    </h1>
                    {chapters}
                </div>
            </div>
        )}
        </ThemeContext.Consumer>
    )
}

export default Article