import "../Toggler.scss";
import {useState} from "react";

const LanguageToggler = ({toggleLanguage, languages, theme}) => {

    const abbreviations = [];

    for(let key in languages) {
        if(languages.hasOwnProperty(key)) {
            abbreviations.push(languages[key].abbreviation);
        }
    }

    const frToggleIconName = "toggle_on"
    const enToggleIconName = "toggle_off"

    let [toggleIcon, setToggleIcon] = useState(enToggleIconName)

    return (
        <div className="toggle" style={{color: theme.color, left: "0", top: "0"}}>
            <span>{abbreviations[0]}</span>
            <a onClick={() => {
                toggleLanguage();
                setToggleIcon(toggleIcon === frToggleIconName ? enToggleIconName : frToggleIconName);
            }}><span className="material-icons toggleIcon">{toggleIcon}</span></a>
            <span>{abbreviations[1]}</span>
        </div>
    )
}

export default LanguageToggler;