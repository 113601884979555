import './Copyright.scss'

const Copyright = ({theme}) => {

    return (
        <div className="copyright" style={{color: theme.color}}>
            © {new Date().getFullYear() } {document.title} | {document.head.querySelector("[name~=author][content]").content}
        </div>
    )
}

export default Copyright;