import '../Toggler.scss'
import {useState} from "react";

const ThemeToggler = ({toggleTheme, theme}) => {

    const lightToggleIconName = "toggle_on"
    const darkToggleIconName = "toggle_off"

    let [toggleIcon, setToggleIcon] = useState(darkToggleIconName)

    return (
        <div className="toggle" style={{color: theme.color, right: "0", top: "0"}}>
            <span className="material-icons">light_mode</span>
            <a onClick={() => {
                toggleTheme();
                setToggleIcon(toggleIcon === lightToggleIconName ? darkToggleIconName : lightToggleIconName);
            }}><span className="material-icons toggleIcon">{toggleIcon}</span></a>
            <span className="material-icons">dark_mode</span>
        </div>
    )
}

export default ThemeToggler;