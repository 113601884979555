import * as json from "../json/content.json";
import { createContext } from "react";

const languages = {
    fr: {
        json: json.fr,
        abbreviation: "FR"
    },
    en: {
        json: json.en,
        abbreviation: "EN"
    }
};

const LanguageContext = createContext({
    language: languages.fr.json,
    toggleLanguage: () => {},
});

export default LanguageContext;
export { languages };
