import { createContext } from "react";

const themes = {
  light: {
    backgroundColor: "#fff",
    color: "#111"
  },
  dark: {
    backgroundColor: "#111",
    color: "#fff"
  },
};

const ThemeContext = createContext({
  theme: themes.light,
  toggleTheme: () => {},
});

export default ThemeContext;
export { themes };
